import { useEffect, useState } from 'react';
import { analytics, logEvent, submitDetailsFunction} from "../../firebase/config.js"
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import styles from './ProvideDetails.module.scss';
import 'animate.css'

const ProvideDetails = ({ setSubmitted }: any) => {
    const [userDetails, setUserDetails] = useState<any>({
        fullName: "",
        email: "",
        phoneNumber: ""
    })
    const [receiveUpdates, setReceiveUpdates] = useState<string>("");
    useEffect(() => {
        console.log(receiveUpdates)
        logEvent(analytics, `provide_details`);
    }, [receiveUpdates])

    const onFormSubmission = async () => {
        setSubmitted(true);
        await submitDetailsFunction({fullName: userDetails.fullName, email: userDetails.email, phoneNumber: userDetails.phoneNumber})
    };

    return (
        <div className={styles.container}>
            <h1 className={`${styles.title} animate__animated animate__fadeInDown`}>Amazing, you’re a true Hamas supporter!</h1>
            <p>Please provide your details so a donation to Hamas can be made on your behalf.</p>
            {/* <p className={styles.subTitle}></p> */}

            <TextField
                fullWidth
                onChange={(e) => { setUserDetails((prevState: any) => { return { ...prevState, fullName: e.target.value } }) }}
                className={styles.inputField}
                label="Full Name"
                variant="standard"
            />
            <TextField
                fullWidth
                onChange={(e) => { setUserDetails((prevState: any) => { return { ...prevState, email: e.target.value } }) }}
                className={styles.inputField}
                label="Email"
                variant="standard"
            />
            <TextField
                fullWidth
                onChange={(e) => { setUserDetails((prevState: any) => { return { ...prevState, phoneNumber: e.target.value } }) }}
                className={styles.inputField}
                label="Mobile Phone"
                variant="standard"
            />
            <label className={styles.checkboxContainer}>
                <input type="checkbox" onChange={(e) => setReceiveUpdates(e.target.value)} />
                <span>I want to receive updates and promotion by Hamas</span>
            </label>
            <section className={styles.buttonsSection}>
                <Button variant="contained" color="primary" className={styles.submitButton} onClick={() => setSubmitted(true)}>
                    SKIP
                </Button>
                <Button variant="contained" color="primary" className={styles.submitButton} onClick={onFormSubmission}>
                    SUBMIT
                </Button>
            </section>
        </div>
    );
}

export default ProvideDetails;
