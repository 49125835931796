import React, { useEffect, useState } from 'react';
import styles from './IntroScreen.module.scss';
import '../../fonts.css'
import 'animate.css';
import PopUpStartChallenge from '../PopUpStartChallenge/PopUpStartChallenge.cmp';
import { useRecoilState } from 'recoil';
import { question } from '../../utils/models';
import { fetchedQuestions } from '../../store/atoms';
import { HttpsCallableResult } from '@firebase/functions';
import { getQuestionsFunction } from '../../firebase/config';

interface IntroScreenProps {
  // setOver18State: () => void;
  setShowPopUp: () => void;
  setChangeBackGroundColor: () => void;
}

const IntroScreen: React.FC<IntroScreenProps> = ({ setShowPopUp, setChangeBackGroundColor }: IntroScreenProps) => {
  const [allQuestions, setAllQuestions] = useRecoilState<question[]>(fetchedQuestions)
  const [disabledStart, setDisabledStart] = useState(true);

  useEffect(() => {
    const setQuestions = async () => {
      const result: HttpsCallableResult = await getQuestionsFunction();
      // Extract data and assert the type
      const data: question[] = result.data as question[];
      setAllQuestions(data);
    }
    setQuestions()
  }, [])


  return (
    <div className={styles.container} >
      <div className={styles.titleContainer}>
        <span className={`${styles.title} ${styles.upper}`}>THE GAZA</span>
        <span className={`${styles.title} ${styles.lower}`}>CHALLENGE</span>
      </div>
      <p className={styles.description}>Take the Gaza Challenge and see if you’re a true Hamas supporter.</p>
      <p className={styles.contribution}>
        Upon completion, a contribution
        will be made in your name to Hamas
      </p>
      <section className={styles.checkBoxAndWarning}>
        {/* <p className={styles.warning}>
          This website is designed for an adult audience only.
        </p> */}
        {/* <label className={styles.checkboxContainer}>
          <input type="checkbox" onClick={() => setDisabledStart(!disabledStart)} />
          <span>I am over 18</span>
        </label> */}
      </section>
      <button onClick={() => {

        setShowPopUp()
        setChangeBackGroundColor()
      }
      } className={`${styles.startButton} animate__animated animate__headShake animate__infinite infinite`}>Start</button>

    </div>
  );
};

export default IntroScreen;
