import React, { useEffect } from 'react';
import { analytics, logEvent} from "../../firebase/config.js"
import styles from './FinishedPage.module.scss';
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import 'animate.css';

interface finishedPageProps {
    score: number;
    questions: any[];
}

const FinishedPage = ({ score, questions }: finishedPageProps) => {
    useEffect(() => {
        logEvent(analytics, `score_page`);
    }, [])

    return (
        <div className={styles.container}>
            <h2 className={styles.smallHeader}>Great job, you finished the challenge!</h2>
            <h1 className={`animate__animated animate__heartBeat ${styles.bigHeader}`}>Your score: {score}</h1>
            <div className={styles.content}>
                {questions.map((question, index) => (
                    <div key={index} className={styles.question}>
                        <span className={question.correct ? styles.correct : styles.incorrect}>
                            {question.correct ? <DoneRoundedIcon fontSize='small' sx={{ borderRadius: "50%", color: "white", backgroundColor: "green", padding: "3px" }} /> : <CloseRoundedIcon fontSize='small' sx={{ borderRadius: "50%", color: "white", backgroundColor: "red", padding: "3px" }} />}
                        </span>
                        <span className={styles.questionText}>{question.text}</span>
                        <span className={styles.answer}>{question.answer}</span>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default FinishedPage;
