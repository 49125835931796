import React, { useEffect, useState } from 'react';
import logo from './logo.svg';
import './App.css';
import IntroScreen from './cmps/IntroScreen/IntroScreen.cmp';
import SupportLevelScreen from './cmps/SupportLevelScreen/SupportLevelScreen.cmp';
import { RecoilRoot, useRecoilState } from 'recoil';
import SharePage from './cmps/SharePage/SharePage.cmp';
import PopUpStartChallenge from './cmps/PopUpStartChallenge/PopUpStartChallenge.cmp';


function App() {
  const [over18, setOver18] = useState<boolean>(false);
  const [showPopUp, setShowPopUp] = useState(false);
  const [changeBackGroundColor, setChangeBackGroundColor] = useState<boolean>(false)


  const closePopUp = () => {
    setShowPopUp(false)
    setChangeBackGroundColor(false)
  }




  return (
    <RecoilRoot>
      <div className="App" style={{ backgroundColor: changeBackGroundColor ? "rgb(94, 68, 2, 0.40)" : "" }}>
        {!over18 ? <IntroScreen setShowPopUp={() => setShowPopUp(true)} setChangeBackGroundColor={() => setChangeBackGroundColor(true)} />
          :
          <SupportLevelScreen />}
        <PopUpStartChallenge isVisible={showPopUp} onClose={() => setOver18(true)} closePopUp={closePopUp} />
      </div>
    </RecoilRoot>
  );
}

export default App;
