
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import { getFunctions, connectFunctionsEmulator, httpsCallable } from "firebase/functions";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDNROuwCaePyraEPLFmenrqvZntkDjvshc",
  authDomain: "pro-gaza-challenge.firebaseapp.com",
  projectId: "pro-gaza-challenge",
  storageBucket: "pro-gaza-challenge.appspot.com",
  messagingSenderId: "60358202608",
  appId: "1:60358202608:web:06c4d742bb466946091e35",
  measurementId: "G-6JLX15TS61"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const functions = getFunctions(app);
const answerQuestionFunction = httpsCallable(functions, 'answerQuestion');
const getQuestionsFunction = httpsCallable(functions, 'getQuestions');
const submitDetailsFunction = httpsCallable(functions, 'submitDetails');

// set up for local function emulator
if (process.env.NODE_ENV === 'development') {
    connectFunctionsEmulator(functions, 'localhost', 5001);
  }

export { answerQuestionFunction, getQuestionsFunction, submitDetailsFunction, analytics, logEvent}