import React, { useState, useEffect } from 'react';
import { analytics, logEvent } from "../../firebase/config.js"
import styles from './PopUpStartChallenge.module.scss';
import 'animate.css';

type PopupProps = {
    isVisible: boolean;
    onClose: () => void;
    closePopUp: () => void;
};

const PopUpStartChallenge: React.FC<PopupProps> = ({ isVisible, onClose, closePopUp }) => {
    const [disabledStart, setDisabledStart] = useState(true);
    useEffect(() => {
        logEvent(analytics, 'start_challenge_popup');
    })
    const animationClass = isVisible ? 'animate__animated animate__slideInUp' : 'animate__animated animate__slideOutDown';

    return (
        <>
            <div
                className={`${styles.backdrop} ${isVisible ? styles.visible : ''}`}
                onClick={() => {
                    closePopUp();
                    setDisabledStart(true);
                }}
            />


            <div
                className={`${styles.popup} ${animationClass}`}
                onClick={() => {
                    closePopUp();
                    setDisabledStart(true);
                }}
            >
                <div onClick={(e) => e.stopPropagation()}>

                    <p className={styles.popup__text}>
                        This website is designed for an adult audience only.
                    </p>

                    <div className={styles.popup__actions}>
                        <label className={styles.popup__checkboxLabel}>
                            <input type="checkbox" onChange={() => setDisabledStart(!disabledStart)} />
                            I am over 18 years old.
                        </label>
                    </div>
                    <button
                        className={`${styles.popup__button} ${disabledStart ? styles.disabledStartButton : ''}`}
                        disabled={disabledStart}
                        onClick={() => {
                            onClose();
                            closePopUp();
                        }}
                    >
                        Start Challenge
                    </button>
                </div>
            </div>

        </>
    );
};

export default PopUpStartChallenge;
