import React, { useState, useEffect } from 'react';
import styles from './Timer.module.scss';


type Visibility = 'visible' | 'hidden'

interface TimerProps{
    onEnd: Function;
    visible: Visibility | undefined;
}

const Timer = ({ onEnd, visible }: TimerProps) => {
    const [seconds, setSeconds] = useState(10);

    useEffect(() => {
        if (seconds > 0) {
            const timerId = setTimeout(() => {
                setSeconds(seconds - 1);
            }, 1000);

            return () => clearTimeout(timerId);
        } else {
            onEnd();
        }
    }, [seconds]);

    return (
        <div className={styles.timer} style={{visibility: visible}}>
            {seconds}
        </div>
    );
};

export default Timer;
