import React, { useEffect, useState } from 'react';
import Slider from '@mui/material/Slider';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import { analytics, logEvent} from "../../firebase/config.js"
import styles from './SupportLevelScreen.module.scss';
import { StepLabel, styled } from '@mui/material';
import { useRecoilState, useRecoilValue } from 'recoil';
import { fetchedQuestions, numberOfVideos, selectedAnswer, supportLevel } from '../../store/atoms';
import VideoAndQuestion from '../VideoAndQuestion/VideoAndQuestion.cmp';
import 'animate.css';
import { question } from '../../utils/models';
import ProvideDetails from '../ProvideDetails/ProvideDetails.cmp';
import SharePage from '../SharePage/SharePage.cmp';

const steps = ['', '', ''];

interface StyledStepIconProps {
  active?: boolean;
  completed?: boolean;
}



const StyledStepIcon = styled('div')<StyledStepIconProps>(({ theme, active, completed }) => ({
  width: '10px',
  height: '10px',
  borderRadius: '50%',
  border: `2px solid #008100`,
  backgroundColor: completed || active ? '#008100' : 'transparent',
  zIndex: 1,
  color: active || completed ? '#008100' : 'currentColor',
}));



const SupportLevelScreen: React.FC = () => {
  const [activeStep, setActiveStep] = useState<number>(0);
  const [videoNumber, setVideoNumber] = useState<number>(0)
  const [sliderValue, setSliderValue] = useRecoilState(supportLevel);
  const [nextButtonDisabled, setNextButtonDisabled] = useState(true);
  const [showFinishedMessage, setShowFinishedMessage] = useState(false);
  const [message, setMessage] = useState<any>({ msg: "", style: {} })
  const [selectedButton, setSelectedButton] = useRecoilState<string>(selectedAnswer);
  const [moveToNextPage, setMoveToNextPage] = useState<boolean>(false);
  const videosArrLength = useRecoilValue<number>(numberOfVideos);
  const allQuestions = useRecoilValue<question[]>(fetchedQuestions)
  const [submitted, setSubmitted] = useState<boolean>(false);


  const handleStep = () => {
    setActiveStep(activeStep + 1);
    console.log(activeStep + 1)
  };

  useEffect(() => {
    console.log("activeStep", activeStep);
    logEvent(analytics, `support_level_screen_${activeStep}`);
  }, [activeStep])

  const nextButtonFunction = () => {
    if (activeStep === 2) {
      setMoveToNextPage(true)
    } else {
      activeStep === 0 ? handleStep() : setNextVideo()
      setSelectedButton("")
      setMessageToUser({ msg: "", style: {} })
      console.log("videoNumber", videoNumber, "allQuestions.length", allQuestions.length);
      if (allQuestions.length === videoNumber) {
        handleStep()
        setNextButtonDisabled(false)
      }
    }
  }

  useEffect(() => {
    if (sliderValue > 0) {
      setNextButtonDisabled(false)
    }
    else if (sliderValue === 0 && activeStep !== 2) {
      setNextButtonDisabled(true)
    }

  }, [sliderValue])

  const setMessageToUser = (messageObject: any) => {
    setMessage(messageObject)
  }
  const setNextVideo = () => {
    setVideoNumber(videoNumber + 1)
  }
  return (
    <div className={styles.container} style={{ maxWidth: activeStep > 0 && allQuestions.length > videoNumber ? "700px" : "" }}>
      <div className={styles.contentContainer} style={activeStep > 0 ? { maxWidth: "700px", width: "100%" } : {}}>
        <h2 className={styles.SupportLevelHeader}><span style={{ color: activeStep === 2 ? "green" : "black" }}>THE GAZA </span><span style={{ color: activeStep === 2 ? "red" : "black" }}>CHALLENGE</span></h2>
        {!moveToNextPage && <Stepper nonLinear activeStep={activeStep} className={styles.stepper} >
          {steps.map((label, index) => (
            <Step key={index}>
              <StepLabel StepIconComponent={(props) => <StyledStepIcon {...props} completed={index < activeStep} />}>
                {label}
              </StepLabel>
              {/* <StepButton onClick={handleStep(index)}></StepButton> */}
            </Step>
          ))}
        </Stepper>}
        {
          (activeStep === 0 || activeStep === 2) && !moveToNextPage ?
            <>
              <div className={styles.question}>
                {activeStep === 0 ? 'To what extent do you support Hamas?' : 'To what extent do you support Hamas now?'}
              </div>
              <Slider
                defaultValue={0}
                value={sliderValue}
                aria-labelledby="discrete-slider"
                valueLabelDisplay="on"
                className={styles.slider}
                onChange={(e, newValue) => { setSliderValue(newValue as number) }}
                sx={{
                  width: "85%",
                  color: "#008100", '& .MuiSlider-rail': {
                    bgcolor: '#a80b0086'
                  },
                  '& .MuiSlider-track': {
                    bgcolor: '#008100'
                  }, '& .MuiSlider-thumb': {
                    height: 27,
                    width: 27,
                    // backgroundColor: '#fff',
                    border: '1px solid currentColor',
                    '&:hover': {
                      boxShadow: '0 0 0 8px #a80b0030',
                    }, '&:active': {
                      boxShadow: '0 0 0 15px #a80b0030',
                    }
                  },
                  '& .MuiSlider-valueLabel': {
                    lineHeight: 1.2,
                    fontSize: 12,
                    background: 'unset',
                    padding: 0,
                    width: 32,
                    height: 32,
                    borderRadius: '50% 50% 50% 0',
                    backgroundColor: '#008100',
                    transformOrigin: 'bottom left',
                    transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
                    '&:before': { display: 'none' },
                    '&.MuiSlider-valueLabelOpen': {
                      transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
                    },

                    '& > *': {
                      transform: 'rotate(45deg)',
                    },
                  },
                }}
              />
            </>
            :
            // moveToNextPage ? <DonationPage sliderValue={sliderValue} /> :
            moveToNextPage && sliderValue && !submitted ? <ProvideDetails setSubmitted={setSubmitted} /> : !sliderValue || submitted ? <SharePage /> :
              <VideoAndQuestion setNextButtonDisabled={setNextButtonDisabled} setMessage={setMessageToUser} videoNumber={videoNumber} />
        }
      </div>
      <section className={styles.buttonAndMessageContainer}>
        <p style={message.style}>{message.msg || " "}</p>
        {!moveToNextPage && <button title='next button' onClick={nextButtonFunction} style={{ visibility: allQuestions.length + 1 === videoNumber && activeStep === 1 ? "hidden" : "visible" }} disabled={nextButtonDisabled} className={`${styles.nextButton} ${nextButtonDisabled ? styles.nextButtonDisabledStyles : 'animate__animated animate__headShake animate__infinite infinite'} `}>
          <div className={styles.nextButtonContentDiv} >
            <ArrowForwardIosIcon />
            <span>Next</span>
          </div>
        </button>}
      </section>
    </div>
  );
};

export default SupportLevelScreen;
