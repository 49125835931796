import { RecoilState, atom } from 'recoil'
import { answer, question } from '../utils/models'

export const fetchedQuestions: RecoilState<question[]> = atom<question[]>({
    key: 'fetchedQuestions',
    default: []
})

export const supportLevel: RecoilState<number> = atom({
    key: 'supportLevel',
    default: 0
})
export const selectedAnswer: RecoilState<string> = atom({
    key: 'selectedAnswer',
    default: ""
})

export const numberOfVideos: RecoilState<number> = atom({
    key: 'numberOfVideos',
    default: 1
})

export const userScore: RecoilState<number> = atom({
    key: 'userScore',
    default: 0
})

export const userAnswers: RecoilState<answer[]> = atom<answer[]>({
    key: 'userAnswers',
    default: []
})