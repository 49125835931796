import React, { useEffect, useRef, useState } from 'react';
import { Stepper, Step, StepLabel, styled, Skeleton } from '@mui/material';
import ReactPlayer from 'react-player';
import { analytics, logEvent, answerQuestionFunction} from "../../firebase/config.js"
import styles from './VideoAndQuestion.module.scss';
import Timer from '../Timer/Timer.cmp';
import { useRecoilState, useRecoilValue } from 'recoil';
import { fetchedQuestions, numberOfVideos, selectedAnswer, supportLevel, userAnswers } from '../../store/atoms';
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import PauseCircleOutlineRoundedIcon from '@mui/icons-material/PauseCircleOutlineRounded';
import PlayCircleOutlineRoundedIcon from '@mui/icons-material/PlayCircleOutlineRounded';
import FullscreenRoundedIcon from '@mui/icons-material/FullscreenRounded';
import FullscreenExitRoundedIcon from '@mui/icons-material/FullscreenExitRounded';
import YesNoButtons from '../YesNoButtons/YesNoButtons.cmp';
import 'animate.css';
import FinishedPage from '../FinishedPage/FinishedPage.cmp';
import { answer, question } from '../../utils/models';

const useStyles = {
  stepperRoot: {
    backgroundColor: 'transparent',
    padding: '20px 0',
    display: 'flex',
    justifyContent: 'center',
  },
  stepIconRoot: {
    color: 'lightgray',
    '&.active': {
      color: 'red',
    },
    '&.completed': {
      color: 'green',
      zIndex: 1,
      fontSize: 30,
    },
  },
};

interface StyledStepIconProps {
  active?: boolean;
  completed?: boolean;
  index: number; // Add index here
  videoNumber: number; // Add videoNumber here
}

const StyledStepIcon = styled('div')<StyledStepIconProps>(({ theme, active, completed, index, videoNumber }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '25px',
  height: '25px',
  borderRadius: '50%',
  // border: `2px solid #008100`,
  fontSize: '14px',
  // lineHeight: '25px',
  backgroundColor: active ? '#A80A00' : completed ? "#008100" : 'rgba(168, 10, 0, 0.20)',
  zIndex: 1,
  color: active || completed ? 'white' : '#A80A00',
  animation: completed && index === videoNumber - 1 ? 'bounceIn' : '',
  animationDuration: '1s'
}));

interface VideoAndQuestionProps {
  setMessage: (message: any) => void;
  setNextButtonDisabled: (state: boolean) => void;
  videoNumber: number;

}


function VideoAndQuestion({ setMessage, setNextButtonDisabled, videoNumber }: VideoAndQuestionProps) {
  // const [activeStep, setActiveStep] = useState(0);
  const [selectedButton, setSelectedButton] = useRecoilState<string>(selectedAnswer);
  const [videosArrLength, setVideosArrLength] = useRecoilState<number>(numberOfVideos);
  const [isPlaying, setIsPlaying] = useState(false);
  const [fullScreen, setFullScreen] = useState<boolean>(false)
  const playerRef = useRef<ReactPlayer>(null);
  const playerWrapperRef = useRef<HTMLDivElement | null>(null);
  const sliderValue = useRecoilValue(supportLevel)
  const [videoLoaded, setVideoLoaded] = useState(false);
  const [showChosenAnswer, setShowChosenAnswer] = useState<boolean>(false);
  const allQuestions = useRecoilValue<question[]>(fetchedQuestions)
  const [chosenUserAnswers, setChosenUserAnswers] = useRecoilState<answer[]>(userAnswers)
  const [score, setScore] = useState<number>(0);


  // const steps: question[] = [{ url: "YOUR_VIDEO_URL", question: "Did Hamas kill and burn babies?" }, { url: "YOUR_VIDEO_URL", question: "Did Hamas rape and kill women?" }, { url: "YOUR_VIDEO_URL", question: "Did Hamas kill elderly holocaust survivors?" }, { url: "YOUR_VIDEO_URL", question: "Did Hamas burn people alive?" }, { url: "YOUR_VIDEO_URL", question: "Does Hamas have summer camps for children?" }, { url: "YOUR_VIDEO_URL", question: "Does Hamas use civilians as human shield?" }];

  // const handleNext = () => {

  // }
  useEffect(() => {
    logEvent(analytics, `question_${videoNumber}`);
    if (videoNumber < allQuestions.length + 1)
      setNextButtonDisabled(true)
    else {
      setNextButtonDisabled(false)
    }
  }, [])

  const handleEnd = () => {
    // setMessage({msg:"This is the correct answer!", style: {color: "#008100"}})
    setIsPlaying(true);
    setSelectedButton("NO")

  }

  useEffect(() => {
    if (selectedButton)
      setChosenUserAnswers(prevState => [...prevState, { text: allQuestions[videoNumber].question_text, answer: selectedButton, correct: selectedButton === "YES" }])
    if (selectedButton === "YES") {
      setScore(prevScore => prevScore + 1)
    }
  }, [selectedButton])


  useEffect(() => {
    if (videoNumber < allQuestions.length) {
      setNextButtonDisabled(true);
      setIsPlaying(false);
    }
  }, [videoNumber])

  const togglePlay = () => {
    setIsPlaying(!isPlaying);
  };


  const handleFullscreenChange = () => {
    const doc = document as any;
    if (
      doc.fullscreenElement ||
      doc.webkitFullscreenElement ||
      doc.mozFullScreenElement
    ) {
      setFullScreen(true);
    } else {
      setFullScreen(false);
    }
  };


  useEffect(() => {
    document.addEventListener('fullscreenchange', handleFullscreenChange);
    document.addEventListener('webkitfullscreenchange', handleFullscreenChange);
    document.addEventListener('mozfullscreenchange', handleFullscreenChange);

    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
      document.removeEventListener('webkitfullscreenchange', handleFullscreenChange);
      document.removeEventListener('mozfullscreenchange', handleFullscreenChange);
    };
  }, []);


  const handleFullScreen = () => {
    const screen = playerWrapperRef.current as any;
    const doc = document as any;

    if (fullScreen) {
      // Exit full screen
      if (doc.fullscreenElement || doc.webkitFullscreenElement || doc.mozFullScreenElement || doc.msFullscreenElement) {
        if (doc.exitFullscreen) {
          doc.exitFullscreen();
        } else if (doc.webkitExitFullscreen) {
          doc.webkitExitFullscreen();
        } else if (doc.mozCancelFullScreen) {
          doc.mozCancelFullScreen();
        } else if (doc.msExitFullscreen) {
          doc.msExitFullscreen();
        }
        setFullScreen(false);
      }
    } else {
      // Enter full screen
      if (screen && screen.requestFullscreen) {
        screen.requestFullscreen();
      } else if (screen && screen.webkitRequestFullscreen) {
        // Safari
        screen.webkitRequestFullscreen();
      } else if (screen && screen.mozRequestFullScreen) {
        // Firefox
        screen.mozRequestFullScreen();
      }
      setFullScreen(true);
    }
  };

  const YesOnClick = async () => {
    setMessage({ msg: "Continue to the next question", style: { color: "black" } })
    setSelectedButton("YES")
    setNextButtonDisabled(false)

    await answerQuestionFunction({questionText: allQuestions[videoNumber].question_text, answer: "YES"})
  }
  const NoOnClick = async () => {
    setMessage({ msg: "Wait until the video ends to continue to the next question", style: { color: "black" } })
    setIsPlaying(true);
    setSelectedButton("NO");

    await answerQuestionFunction({questionText: allQuestions[videoNumber].question_text, answer: "NO"})
  }

  return (
    <>

      <div className={styles.videoContainer}>
        {
          videoNumber === allQuestions.length ? <FinishedPage score={score} questions={chosenUserAnswers} /> :
            <>
              <Stepper connector={null} style={useStyles.stepperRoot} activeStep={videoNumber} alternativeLabel>
                {allQuestions.map((_, index) => (
                  <Step key={index} style={{ padding: '0 0.7vw' }}>
                    <StepLabel
                      StepIconComponent={(props) => (
                        <StyledStepIcon
                          {...props}
                          active={props.active}
                          completed={props.completed}
                          index={index}
                          videoNumber={videoNumber}
                        >
                          {props.completed ? <DoneRoundedIcon fontSize='small' /> : index + 1}
                        </StyledStepIcon>
                      )}
                    />
                  </Step>
                ))}
              </Stepper>

              <>
                {
                  [...allQuestions].splice(videoNumber, 1).map((video: question, index: number) =>

                  (
                    <div key={video.question_text} className={styles.videoAndQuestionContainer}>
                      <div className={styles.questionTitle} >{video.question_text}</div>

                      {videoLoaded && !selectedButton ? <Timer onEnd={handleEnd} visible={selectedButton ? "hidden" : "visible"} /> : <div className={styles.chosenAnswer}><span>{selectedButton}</span></div>}
                      <div className={styles.skeletonAndVideoContainer}>

                        <div className={styles.reactPlayerContainer} ref={playerWrapperRef} style={{ visibility: videoLoaded ? "visible" : "hidden" }}>
                          <ReactPlayer
                            className={styles.reactPlayer}
                            ref={playerRef}
                            onReady={() => setVideoLoaded(true)}
                            url='/testvideo.mp4'
                            controls={false}
                            playing={isPlaying}
                            width='100%'
                            height='100%'
                            style={{ maxWidth: !fullScreen ? '700px' : "" }}
                            onEnded={() => {
                              setNextButtonDisabled(false);
                              setIsPlaying(false);

                              if (selectedButton !== "YES")
                                setMessage({ msg: "Continue to the next question", style: { color: "black" } })
                            }}
                          />
                          {selectedButton !== "" && <section className={styles.playerButtonsContainer}>
                            <button title="play/pause" disabled={selectedButton === ""} onClick={togglePlay}>{isPlaying ? <PauseCircleOutlineRoundedIcon fontSize='large' sx={{ color: "white" }} /> : <PlayCircleOutlineRoundedIcon fontSize='large' sx={{ color: "white" }} />}</button>
                            <button title="fullscreen" disabled={selectedButton === ""} onClick={handleFullScreen}>{!fullScreen ? <FullscreenRoundedIcon fontSize='large' sx={{ color: "white" }} /> : <FullscreenExitRoundedIcon fontSize='large' sx={{ color: "white" }} />}</button>
                          </section>}
                          {selectedButton === "" && <YesNoButtons YesOnClick={YesOnClick} NoOnClick={NoOnClick} selectedButton={selectedButton} stylingType={"video"} />}
                        </div>
                        {!videoLoaded && <Skeleton variant="rectangular" width='100%' height='100%' animation={"wave"} style={{ maxWidth: '700px', position: "absolute", top: "0", zIndex: "50" }} />}
                      </div>



                    </div>
                  )
                  )
                }

              </>

              {/* <div className={styles.buttonGroup}>
          <button disabled={selectedButton !== ""} onClick={} style={selectedButton === "YES" ? { backgroundColor: "#008100" } : {}}>YES</button>
          <button disabled={selectedButton !== ""} onClick={} style={selectedButton === "NO" ? { backgroundColor: "#008100" } : {}}>NO</button>
            </div> */}
            </>
        }
      </div>

    </>
  );
}

export default VideoAndQuestion;
