import React from 'react';
import styles from './SharePage.module.scss';
import Facebook from '../../assets/Facebook.svg'
import Instagram from '../../assets/Instagram.svg'
import Linkedin from '../../assets/Linkedin.svg'
import Snapchat from '../../assets/Snapchat.svg'
import Telegram from '../../assets/Telegram.svg'
import Twitter from '../../assets/Twitter.svg'
import { useRecoilValue } from 'recoil';
import { supportLevel } from '../../store/atoms';


const ShareButton = ({ icon, platform, shareLink }: any) => (
  <button title={`${platform}`}className={styles.shareButton} onClick={() => window.open(shareLink, "_blank")}>
    <img src={icon} alt={`${platform}`} />
  </button>
);

const SharePage = () => {
    const sliderValue = useRecoilValue(supportLevel)
  return (
    <div className={styles.container}>
      <p className={styles.subtitle} style={sliderValue !== 0 ? {fontSize: "clamp(1rem, 6vw, 2.5rem)", fontWeight: "bold" } : {}}>
     { sliderValue === 0 ? 
     <>
     That’s great!<br/>
        We need your help to<br/>
        spread the truth about<br/>
         Hamas<br/>
        #GazaChallenge 
     </>
        :
        <>
        <span className={`${styles.spanAnimation} ${styles.span1}`}>Thank you for<br/>
         supporting Hamas.<br/></span>
        <span className={`${styles.spanAnimation} ${styles.span2}`}>With your help, Hamas<br/>
        will continue to mutilate<br/>
        babies,rape women in<br/>
        front of their children,<br/></span>
        <span className={`${styles.spanAnimation} ${styles.span3}`}>tear embryos from their<br/>
        mother’s uterus, and<br/>
        burn people alive.<br/></span>
        <span className={`${styles.spanAnimation} ${styles.span4}`}>You are the epitome of<br/>
        humanitarianism and<br/>
        morality!</span>
        </>
        }
      </p>
      <p className={styles.shareLabel}>Share</p>
      <div className={styles.buttons}>
        {/* You can replace these with actual icons */}
        <div>
        <ShareButton icon={Facebook} platform="Facebook" shareLink={'https://www.facebook.com/sharer/sharer.php?u=https://google.com'} />
        <ShareButton icon={Twitter} platform="Twitter" shareLink={'https://twitter.com/intent/tweet?url=https://google.com'} />
        <ShareButton icon={Snapchat} platform="Snapchat"  />
        </div>
        <div>
        <ShareButton icon={Instagram} platform="Instagram" />
        <ShareButton icon={Linkedin} platform="LinkedIn" shareLink={'https://www.linkedin.com/shareArticle?mini=true&url=https://google.com'}/>
        <ShareButton icon={Telegram} platform="Telegram" shareLink={'https://telegram.me/share/url?url=https://google.com&text={text}'} />
        </div>
      </div>
      <p className={styles.hashTag}>#GazaChallenge</p>
    </div>
  );
};

export default SharePage;
