import React from 'react'
import styles from './YesNoButtons.module.scss'

interface YesNoButtonsProps {
  YesOnClick: () => void;
  NoOnClick: () => void;
  selectedButton?: string;
  stylingType?: string;
  buttonOneStyles?: any;
  buttonTwoStyles?: any;
}

const YesNoButtons = ({ selectedButton, YesOnClick, NoOnClick, stylingType, buttonOneStyles, buttonTwoStyles }: YesNoButtonsProps) => {
  return (
    <div className={`${styles.buttonGroup} ${stylingType === "video" ? styles.onVideoFloatingButtons : ""}`}>
      <button disabled={selectedButton !== ""} onClick={YesOnClick} style={selectedButton === "YES" ? { backgroundColor: "#008100" } : {}}>YES</button>
      <button disabled={selectedButton !== ""} onClick={NoOnClick} style={selectedButton === "NO" ? { backgroundColor: "#008100" } : {}}>NO</button>
    </div>
  )
}

export default YesNoButtons